import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../common-ui-modules/service/redux-helper/api-action-creator";
import { DOWNLOAD_QUERY_TOOL_API_URL } from "./constants";

const slice = createSlice({
    name: "downloadQueryTool",
    initialState: {
        downloadDetails: []
    },
    reducers: {
        downloadDetailsReceived: (state, action) => {
            state.downloadDetails = action.payload;
        },
    },
});

export const {
    downloadDetailsReceived,
} = slice.actions;

//custom actions

export const loadDownloadDetails = (userInput: any) => (dispatch: any) => {
    return dispatch(
        apiCallBegan({
            url: `${DOWNLOAD_QUERY_TOOL_API_URL.DOWNLOAD_QUERY}?userId=${userInput.email}&siteId=${userInput.siteId}&skuCode=${userInput.skuCode}`,
            onSuccess: downloadDetailsReceived.type,
            method: "GET",
        }),
    );
};


//selectors
export const getDownloadDetails = (state: any) => state.downloadQueryTool.downloadDetails;


// reducer
export const downloadQueryToolReducer = slice.reducer;
