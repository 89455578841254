import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";

import ClayCard from "@clayui/card";
import ClayForm from "@clayui/form";
import ClayLayout from "@clayui/layout";
import ClayButton from "@clayui/button";

import { Formik } from "formik";
import { FormControl, Alert } from "../../../../../common-ui-modules/components";
import * as Yup from "yup";

import { loadDownloadDetails } from "../../../../../store/DownloadQueryTool/slice";

const QueryDownloadPanel = () => {
    const dispatch = useDispatch();
    const formRef = useRef<any>("");
    const [formData, setFormData] = useState({
        email: "",
        siteId: "",
        skuCode: "",
    });

    const validationSchema = Yup.object().shape({
        email: Yup.string().email("Email is invalid").required("Email is required"),
        siteId: Yup.string().required("Site ID is required"),
    });

    const onSubmit = (formData: any) => {
        const userInputs = {
            email: formData.email.trim(),
            siteId: formData.siteId.trim(),
            skuCode: formData.skuCode.trim(),
        };

        dispatch(loadDownloadDetails(userInputs));
    };

    return (
        <React.Fragment>
            <ClayCard>
                <ClayCard.Body>
                    <Alert
                        isToast={false}
                        alertText={`Enter email address, Site ID and click "Query". This is for External Users only.`}
                    />

                    <Formik
                        initialValues={formData}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        innerRef={formRef}
                        validateOnBlur={false}
                    >
                        {(formik) => (
                            <ClayForm onSubmit={formik.handleSubmit}>
                                <ClayLayout.Row justify="start" className="pt-2">
                                    <ClayLayout.Col size={2}>
                                        <div className="mt-2">Login (Email):</div>
                                    </ClayLayout.Col>
                                    <ClayLayout.Col size={4}>
                                        <FormControl
                                            control="text"
                                            id="email"
                                            name="email"
                                            size="md"
                                            placeholder="Enter Email Id"
                                            value={formik.values.email}
                                        />
                                    </ClayLayout.Col>
                                </ClayLayout.Row>
                                <ClayLayout.Row justify="start" className="pt-2">
                                    <ClayLayout.Col size={2}>
                                        <div className="mt-2">Site ID:</div>
                                    </ClayLayout.Col>
                                    <ClayLayout.Col size={4}>
                                        <FormControl
                                            control="text"
                                            id="siteId"
                                            name="siteId"
                                            size="md"
                                            placeholder="Enter Site Id"
                                            value={formik.values.siteId}
                                        />
                                    </ClayLayout.Col>
                                </ClayLayout.Row>
                                <ClayLayout.Row justify="start" className="pt-2">
                                    <ClayLayout.Col size={2}>
                                        <div className="mt-2">SKU Code:</div>
                                    </ClayLayout.Col>
                                    <ClayLayout.Col size={4}>
                                        <FormControl
                                            control="text"
                                            id="skuCode"
                                            name="skuCode"
                                            size="md"
                                            placeholder="Enter SKU Code"
                                            value={formik.values.skuCode}
                                        />
                                    </ClayLayout.Col>
                                </ClayLayout.Row>
                                <ClayLayout.Row justify="center" className="pt-2">
                                    <ClayLayout.Col size={3}>
                                        <ClayButton
                                            type="submit"
                                            displayType="primary"
                                            className="ml-2"
                                        >
                                            Query
                                        </ClayButton>
                                    </ClayLayout.Col>
                                </ClayLayout.Row>
                            </ClayForm>
                        )}
                    </Formik>
                </ClayCard.Body>
            </ClayCard>
        </React.Fragment>
    );
};

export default QueryDownloadPanel;
