import React, { useEffect, useState } from "react";
import ClayLayout from "@clayui/layout";

import { PageTitle } from "../../common-ui-modules/components";
import QueryDownloadPanel from "./ui/pages/QueryDownloadPanel/QueryDownloadPanel";
import { DOWNLOAD_QUERY_TOOL_HEADING } from "../CompanyManagement/constants";
import DownloadDetails from "./ui/pages/DownloadDetails/DownloadDetails";
import { isInternalUser, isLoggedIn } from "../../utils/utils";
import { useAppSelector } from "../../store/hook";
import { getEnvVariables, getUserData, getUserDetails } from "../../store/global/global.slice";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../utils/constants/routeConstants";
import { AccessDenied } from "../../common-ui-modules/components/AccessDenied/AccessDenied";

const DownloadQueryToolPage = () => {
    const [isInternalUserFlag, setIsInternalUserFlag] = useState("");
    document.title = DOWNLOAD_QUERY_TOOL_HEADING;

    const navigate = useNavigate();
    const envVariables = useAppSelector(getEnvVariables);
    const isLogIn = isLoggedIn(envVariables);
    const userDetails = useAppSelector(getUserDetails);
    const userProfileDetails = useAppSelector(getUserData);
    const isOEMUser = userProfileDetails?.userVO?.isBSNAssistUser || false;

    useEffect(() => {
        if (isLogIn && !userDetails.email) {
            window.location.href = ROUTE_PATHS.LOGOUT;
        } else if (userDetails.email) {
            if (isInternalUser(userDetails.email)) {
                setIsInternalUserFlag("Yes");
            } else if (!isInternalUser(userDetails.email)) {
                setIsInternalUserFlag("No");
            }
        } else if (!isLogIn) {
            navigate(ROUTE_PATHS.HOME);
        }
    }, []);

    return (
        <React.Fragment>
            <div className="body-bg pb-6" id="re-container">
                <div className="mx-5">
                    <ClayLayout.ContainerFluid>
                        {isOEMUser ? (
                            <AccessDenied />
                        ) : (
                            <div>
                                <ClayLayout.Row justify="start" className="align-items-center">
                                    <ClayLayout.Col className="d-flex align-items-left ml-4" size={8}>
                                        <PageTitle title={DOWNLOAD_QUERY_TOOL_HEADING} />
                                    </ClayLayout.Col>
                                </ClayLayout.Row>

                                <React.Fragment>
                                    {isInternalUserFlag === "No" && (
                                        <ClayLayout.Row justify="start" className="align-items-center">
                                            <ClayLayout.Col
                                                className="align-items-left ml-4 pt-4"
                                                size={8}
                                            >
                                                <h2 className="text-red">{`Access Denied!`}</h2>
                                                <p className="text-red">{`You don't have permission to view this page!`}</p>
                                            </ClayLayout.Col>
                                        </ClayLayout.Row>
                                    )}

                                    {isInternalUserFlag === "Yes" && (
                                        <div>
                                            <ClayLayout.Container className="mx-3">
                                                <QueryDownloadPanel />
                                            </ClayLayout.Container>

                                            <ClayLayout.Container className="mx-3">
                                                <DownloadDetails />
                                            </ClayLayout.Container>
                                        </div>
                                    )}
                                </React.Fragment>
                            </div>
                        )}
                    </ClayLayout.ContainerFluid>
                </div>
            </div>
        </React.Fragment>
    );
};

export default DownloadQueryToolPage;
