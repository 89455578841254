import { configureStore } from "./../common-ui-modules/service/redux-helper/store/configureStore";
import { globalReducer } from "./global/global.slice";
import { forgetPasswordReducer } from "./Registration/forgot-password/slice";
import { registrationReducer } from "./Registration/registration/slice";
import { profileBuilderReducer } from "./Registration/profile-builder/slice";
import { userInformationReducer } from "./Registration/userInformation/slice";
import { companyManagementReducer } from "./CompanyManagement/slice";
import { userManagementReducer } from "./UserManagement/slice";
import { customerQueryToolReducer } from "./CustomerQueryTool/slice";
import { externalBannerReducer } from "./ExternalBanner/slice";
import { downloadQueryToolReducer } from "./DownloadQueryTool/slice";

export const store = configureStore({
    reducer: {
        global: globalReducer,
        registration: registrationReducer,
        forgetPassword: forgetPasswordReducer,
        profileBuilder: profileBuilderReducer,
        userInformation: userInformationReducer,
        userManagement: userManagementReducer,
        companyManagement: companyManagementReducer,
        customerQueryTool: customerQueryToolReducer,
        externalBanner: externalBannerReducer,
        downloadQueryTool: downloadQueryToolReducer,
    },
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
